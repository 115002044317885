import request from "@/utils/request";
const api = {
  getworksitelist: "/dsmcore/api/worksite/list", //查询排期列表接口
  updateworksite: "/dsmcore/api/worksite/update", //更新排期列表接口
  addworksite: "/dsmcore/api/worksite/add", //添加排期列表接口
  deleteworksite: "/dsmcore/api/worksite/delete", //删除排期列表接口
  getworksiteplan: "/dsmcore/api/worksite/worksitePlan", //生成工地排期接口
};
export function getWorkSitelist(parameter) {
  return request({
    url: api.getworksitelist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function UpdateWorkSite(parameter) {
  return request({
    url: api.updateworksite,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function AddWorkSite(parameter) {
  return request({
    url: api.addworksite,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteWorkSite(parameter) {
  return request({
    url: api.deleteworksite,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function GetWorkSitePlan(parameter) {
  return request({
    url: api.getworksiteplan,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
